@import "variables";
@import "mixins";
@import "../../theme/variables";

.br-theme-bars-square {

  .br-widget {
    height: 40px;
    white-space: nowrap;

    a {
      display: block;
      width: 30px;
      height: 30px;
      float: left;
      border: 2px solid lighten($primary-color, 20%);
      background-color: white;
      margin: 2px;
      text-decoration: none;
      font-size: 14px;
      line-height: 1.8;
      text-align: center;
      color: lighten($primary-color, 20%);
      font-weight: 600;
      &.br-active,
      &.br-selected {
        border: 2px solid $primary-color;
        color: $primary-color;
      }
    }

    .br-current-rating {
      clear: both;
      width: 330px;
      text-align: center;
      font-weight: 600;
      display: block;
      padding: .5em 0;
      color: #646464;
    }
  }

  .br-readonly {
    a {
      cursor: default;

      &.br-active, &.br-selected {
        border: 2px solid $primary-color;
        color: lighten($primary-color, 10%);
      }
    }
  }

}

@media print {
  .br-theme-bars-square {

    .br-widget {
      a {
        border: 2px solid lighten(black, 70%);
        color: lighten(black, 70%);

        &.br-active,
        &.br-selected {
          border: 2px solid black;
          color: black;
        }
      }
    }

  }
}
