$fontFamily: "Lato", sans-serif;
$fontFamilyMonospaced: "Source Code Pro", sans-serif;

// Colors
$textColor: #757575;
$headerColor: #2d2d2d;
$backgroundColor: #f8f8f8;
$orangeColor: #EDB867;
$greenColor: #50E3C2;
$blueColor: #4278F5;
$greyColor: #b4b4b4;
$redColor: #f7605a;