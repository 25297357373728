@import "variables.scss";
@import "mixins.scss";
.antennaio {
    @include retina-image('../img/antenna.png', '../img/antenna$2x.png', 60px, 60px);
    background-repeat: no-repeat;
    color: lighten($textColor, 35%);
    width: 280px;
    height: 60px;
    display: block;
    margin: auto;
    font-weight: 400;
    font-size: 0.6em; /* 12px */
    line-height: 5.5;
    letter-spacing: 2px;
    margin-bottom: 7em;

    -webkit-transition: color  2s;
    transition: color  2s;

    &:hover {
        color: $textColor;
    }

    span {
        padding: 0 0 0 70px;
    }
}

.bars {
    @include retina-image('../img/bars.png', '../img/bars$2x.png', 80px, 80px);
    width: 80px;
    height: 80px;
    display: block;
    margin: auto;
}

.section-intro {
    text-align: center;
    margin: 3em 0 4em 0;
    max-height: 9999px; /* no font boosting please */

    h1 {
        line-height: 2.4;
    }

    p {
        max-width: 560px;
        margin: auto;
    }

    p.tagline {
        font-size: 0.8em; /* 16px */
        color: lighten($textColor, 20%);
        margin-bottom: 3em;
    }
}

.section-examples {
    position: relative;
    background: $backgroundColor;
}

.section-docs {
    margin: 3em 0;
}

@mixin coloredDocs($color){
    header {
        background: $color;
    }

    strong {
        color: $color;
    }

    a {
        color: $color;

        &:hover {
            color: darken($color, 20%);
        }
    }

    pre {
        background: white;
        border-left: 2px solid $color;
        padding: 0 2em;
    }

    code {
        font-family: $fontFamilyMonospaced;
        font-size: 0.9em; /* 18px */
        color: $color;
    }
}

.docs {
    width: 800px;
    margin: auto;
    max-height: 9999px; /* no font boosting please */
    .how-to-use, .callbacks, .download {
        @include coloredDocs($blueColor);
    }

    .configuration, .faq {
        @include coloredDocs($greenColor);
    }

    .license, .methods {
        @include coloredDocs($orangeColor);
    }

    .instructions {
        padding: 2em 3em;
        background: $backgroundColor;
    }

    .faq {
        p {
            margin: 0 0 .5em 0;
        }

        .question {
            margin-bottom: 2em;
        }

        .question:last-child {
            margin-bottom: 0;
        }
    }
}

.controls {
    position: absolute;
    top: -23px;
    left: 50%;
    margin-left: -190px;

    a {
        display: block;
        width: 150px;
        background-color: $greenColor;
        color: white;
        float: left;
        padding: .3em 1em .5em 1em;
        text-decoration: none;
        text-align: center;
        font-weight: 400;

        -webkit-transition: background-color  2s;
        transition: background-color  2s;

        &:hover {
            background-color: darken($greenColor, 5%);
        }

        &:focus {
            color: lighten($greenColor, 30%);
            background-color: darken($greenColor, 25%);
        }
    }

    a.rating-enable {
        -webkit-border-top-left-radius: 999px;
        -webkit-border-bottom-left-radius: 999px;
        -moz-border-radius-topleft: 999px;
        -moz-border-radius-bottomleft: 999px;
        border-top-left-radius: 999px;
        border-bottom-left-radius: 999px;
    }

    a.rating-disable {
        -webkit-border-top-right-radius: 999px;
        -webkit-border-bottom-right-radius: 999px;
        -moz-border-radius-topright: 999px;
        -moz-border-radius-bottomright: 999px;
        border-top-right-radius: 999px;
        border-bottom-right-radius: 999px;
    }

    a:last-child {
        border-left: 0;
    }

    a.deactivated {
        background: darken($greenColor, 15%);
        color: white;
    }
}

/* ==========================================================================
   Media Queries
   ========================================================================== */

@media only screen and (min-width: 35em) {

}

@media print {
    .controls,
    .github,
    .antennaio {
        display: none;
    }

    .section-intro {
        margin: 1em 0 2em 0;

        p.tagline {
            color: black;
        }
    }

    .section-examples {
        background: transparent;

        .examples {
            padding: 1em 0;
        }
    }

    .section-docs {
        margin: 1em 0;
    }

    .docs {
        .how-to-use,
        .callbacks,
        .download,
        .configuration,
        .faq,
        .license,
        .methods {
            a {
                color: black;
                text-decoration: none;

                &:hover {
                    color: black;
                }
            }

            header {
                background: transparent;
                color: black;
            }

            code, strong {
                color: black;
            }

            pre {
                border: none;
                padding: 0;
            }

            .instructions {
                background: transparent;
            }
        }
    }

}

/* ==========================================================================
   Helper classes
   ========================================================================== */

.hidden {
    display: none !important;
    visibility: hidden;
}

.visuallyhidden {
    border: 0;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
}

.visuallyhidden.focusable:active,
.visuallyhidden.focusable:focus {
    clip: auto;
    height: auto;
    margin: 0;
    overflow: visible;
    position: static;
    width: auto;
}

.invisible {
    visibility: hidden;
}

.clearfix:before,
.clearfix:after {
    content: " ";
    display: table;
}

.clearfix:after {
    clear: both;
}

.clearfix {
    *zoom: 1;
}
